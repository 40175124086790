import { useEffect, useState } from "react";
import Home from "./Screens/home";
import pfp from "./assets/pfp.jpg";
import HomeMobile from "./Screens/homeMobile";

function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    document.title = "Sashv";
    const favicon =
      document.querySelector('link[rel="icon"]') ||
      document.createElement("link");
    favicon.href = pfp;
    favicon.rel = "icon";
    document.head.appendChild(favicon);

    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);

    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  return <Home />;
}

export default App;
