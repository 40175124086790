import React, { useEffect, useState } from "react";
import { FaRegSun, FaRegMoon } from "react-icons/fa";
import { Content } from "./content";
import { Portfolio } from "./portfolio";

const Home = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [category, setCategory] = useState("Home");
  const [selectedSection, setSelectedSection] = useState(0);
  const screenHeight = window.innerHeight;
  const screenWidth = window.innerWidth;

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 900);
    };

    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);

    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  });

  return (
    <div
      style={{
        backgroundColor: darkMode ? "#25252a" : "#FFF5EE",
      }}
      className={
        darkMode
          ? "bg-[#25252a] w-screen min-h-screen flex flex-col items-center text-white transition-all duration-500"
          : "bg-[#FFF5EE] w-screen min-h-screen flex flex-col items-center text-black transition-all duration-500"
      }
    >
      <div
        style={{
          paddingRight: isMobile ? screenWidth * 0.05 : 0,
          paddingLeft: isMobile ? screenWidth * 0.05 : 0,
          width: isMobile ? screenWidth * 0.9 : screenWidth * 0.75,
        }}
      >
        <div style={{ marginTop: screenHeight * 0.1 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div className="flex flex-row">
              <button onClick={toggleDarkMode}>
                {darkMode ? (
                  <FaRegSun style={{ fontSize: 20 }} />
                ) : (
                  <FaRegMoon style={{ fontSize: 20 }} />
                )}
              </button>
              <button onClick={() => setCategory("Home")}>
                <h1
                  className="urbanist"
                  style={{
                    fontWeight: 600,
                    fontSize: 15,
                    marginLeft: isMobile ? 10 : 20,
                    position: "relative",
                    cursor: "pointer",
                    textDecoration: category === "Home" ? "underline" : "none",
                    paddingBottom: "2px",
                  }}
                >
                  A bit about me
                </h1>
              </button>
              <button onClick={() => setCategory("Portfolio")}>
                <h1
                  className="urbanist"
                  style={{
                    fontWeight: 600,
                    fontSize: 15,
                    marginLeft: isMobile ? 10 : 20,
                    position: "relative",
                    cursor: "pointer",
                    textDecoration:
                      category === "Portfolio" ? "underline" : "none",
                    paddingBottom: "2px",
                  }}
                >
                  Portfolio
                </h1>
              </button>
            </div>
            {isMobile ? (
              <></>
            ) : (
              <div className="flex flex-row">
                <h1
                  className="urbanist"
                  style={{
                    fontWeight: 600,
                    fontSize: 15,
                    marginLeft: screenWidth * 0.015,
                    position: "relative",
                    cursor: "pointer",
                  }}
                  onMouseEnter={(e) => {
                    const hoverDiv = e.currentTarget.querySelector("div");
                    if (hoverDiv) hoverDiv.style.width = "100%";
                  }}
                  onMouseLeave={(e) => {
                    const hoverDiv = e.currentTarget.querySelector("div");
                    if (hoverDiv) hoverDiv.style.width = "0";
                  }}
                >
                  <a
                    href="mailto:davesashv@gmail.com"
                    style={{ position: "relative" }}
                  >
                    Reach out
                  </a>
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      width: 0,
                      height: 2,
                      backgroundColor: darkMode ? "white" : "black",
                      transition: "width 0.3s ease",
                    }}
                  ></div>
                </h1>
              </div>
            )}
          </div>
          <div style={{marginTop: screenHeight * 0.005}}>{category === "Home" ? <Content /> : <Portfolio />}</div>
          {/* <Spotify
          style={{ marginTop: screenHeight * 0.03 }}
          width={screenWidth * 0.5}
          height={screenHeight * 0.2}
          link="https://open.spotify.com/track/1v6CmIvbcquM8rKR33Kc6e"
          view="coverart"
        /> */}
        </div>
      </div>
    </div>
  );
};

export default Home;

