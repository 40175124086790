import React, { useEffect, useState } from "react";
import pfp from "../assets/portfoliopfp.png";
import Verba1 from "../assets/Verba/pic1.png";
import Verba2 from "../assets/Verba/pic2.png";
import Verba3 from "../assets/Verba/pic3.png";
import Verba4 from "../assets/Verba/pic4.png";
import Verba5 from "../assets/Verba/pic5.png";
import Verba6 from "../assets/Verba/pic6.png";
import Verba7 from "../assets/Verba/pic7.png";
import Verba8 from "../assets/Verba/pic8.png";
import Giphting1 from "../assets/Giphting/pic1.png";
import Giphting2 from "../assets/Giphting/pic2.png";
import Giphting3 from "../assets/Giphting/pic3.png";
import Giphting4 from "../assets/Giphting/pic4.png";
import Giphting5 from "../assets/Giphting/pic5.png";
import Giphting6 from "../assets/Giphting/pic6.png";
import Giphting7 from "../assets/Giphting/pic7.png";
import Fork1 from "../assets/Fork/pic1.png";
import Fork2 from "../assets/Fork/pic2.png";
import Fork3 from "../assets/Fork/pic3.png";
import Fork4 from "../assets/Fork/pic4.png";
import Fork5 from "../assets/Fork/pic5.png";
import Fork6 from "../assets/Fork/pic6.png";
import THS1 from "../assets/THS/pic1.png";
import THS2 from "../assets/THS/pic2.png";
import THS3 from "../assets/THS/pic3.png";
import THS4 from "../assets/THS/pic4.png";
import THS5 from "../assets/THS/pic5.png";
import THS6 from "../assets/THS/pic6.png";
import Pair1 from "../assets/Pair/pic1.png";
import Pair2 from "../assets/Pair/pic2.png";
import Pair3 from "../assets/Pair/pic3.png";
import Pair4 from "../assets/Pair/pic4.png";
import Lorestry1 from "../assets/Lorestry/pic1.png";
import Lorestry2 from "../assets/Lorestry/pic2.png";
import Lorestry3 from "../assets/Lorestry/pic3.png";
import Lorestry4 from "../assets/Lorestry/pic4.png";
import Lorestry5 from "../assets/Lorestry/pic1.png";
import Lorestry6 from "../assets/Lorestry/pic2.png";
import Lorestry7 from "../assets/Lorestry/pic3.png";
import Lorestry8 from "../assets/Lorestry/pic4.png";
import Lorestry9 from "../assets/Lorestry/pic4.png";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const GalleryModal = ({ images, onClose, medium }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
      onClick={onClose}
    >
      <div
        style={{
          padding: 20,
          backgroundColor: "transparent",
          borderRadius: 20,
          display: "flex",
          width: medium === "pc" ? 650 : 275,
          flexDirection: "column",
          alignItems: "center",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <Carousel
          showArrows={false}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
          autoPlay={true}
          emulateTouch={true}
          dynamicHeight={true}
          useKeyboardArrows={true}
        >
          {images &&
            images.map((image, index) => (
              <div
                key={index}
                style={{
                  maxWidth: medium === "pc" ? 650 : 275,
                  borderRadius: 15,
                }}
              >
                <img
                  src={image}
                  alt={`Project ${index}`}
                  style={{ width: "auto", maxHeight: "100%", borderRadius: 15 }}
                />
              </div>
            ))}
        </Carousel>
        <button
          className="urbanist"
          onClick={onClose}
          style={{ marginTop: 10, color: "white" }}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export const Portfolio = () => {
  const screenHeight = window.innerHeight;
  const screenWidth = window.innerWidth;
  const [isMobile, setIsMobile] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedProjectImages, setSelectedProjectImages] = useState([]);
  const [medium, setMedium] = useState("");
  const [portfolioExpanded, setPortfolioExpanded] = useState(true);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 900);
    };

    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);

    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  });

  const techTags = {
    Flutter: { color: "#29B6F6", count: 1 },
    MySQL: { color: "#00ACC1", count: 1 },
    Firestore: { color: "#FFD54F", count: 1 },
    OAuth: { color: "#EF5350", count: 1 },
    AWS: { color: "#FFB74D", count: 1 },
    React: { color: "#81D4FA", count: 1 },
    "React Native": { color: "#BA68C8", count: 1 },
    Firebase: { color: "#FFD54F", count: 1 },
    K8s: { color: "#326CE5", count: 1 },
    Docker: { color: "#2496ED", count: 1 },
    "Amazon S3": { color: "#569A31", count: 3 },
    NextJS: { color: "#000000", count: 1 },
    Tailwind: { color: "#38B2AC", count: 2 },
    Python: { color: "#3776AB", count: 1 },
    Phantombuster: { color: "#FCC624", count: 1 },
    GraphQL: { color: "#E10098", count: 1 },
    ElephantSQL: { color: "#8E2BE2", count: 1 },
    "Elastic Beanstalk": { color: "#FF9900", count: 1 },
    Express: { color: "#000000", count: 1 },
    "Google Cloud Console APIs": { color: "#4285F4", count: 1 },
    Tensorflow: { color: "#FF6F00", count: 1 },
    Liquid: { color: "#7747FF", count: 1 },
    "Shopify API": { color: "#95BF47", count: 1 },
    "Amazon ECS": { color: "#FF9900", count: 1 },
    "Flask (Python)": { color: "#000000", count: 1 },
    PostgreSQL: { color: "#336791", count: 1 },
    "Firebase Webhooks": { color: "#FFCA28", count: 1 },
    "Netlify Serverless Functions": { color: "#00C7B7", count: 1 },
    MongoDB: { color: "#47A248", count: 1 },
    Twilio: { color: "#F22F46", count: 1 },
    Sentry: { color: "#FB4226", count: 1 },
    Zapier: { color: "#FF4A00", count: 1 },
    "B.E.R.T": { color: "#B11226", count: 1 },
    PyTorch: { color: "#EE4C2C", count: 1 },
  };

  const projects = [
    {
      name: "Verba",
      role: "Founder & CEO",
      description: [
        "Built a platform to help people speak more confidently",
        "Scaled to ~20,000 learners in 195 countries",
        "Led a team of 100+ volunteers and core team of 6 to coordinate outreach efforts, community management, and finances",
      ],
      images: [Verba1, Verba2, Verba3, Verba4, Verba5, Verba6, Verba7, Verba8],
      tech: ["Flutter", "MySQL", "Firestore", "OAuth", "AWS"],
    },
    {
      name: "Camb.ai",
      role: "Founding & Head of Linguistics",
      description: [
        "Developed a video dubbing engine that could convert any content into hundreds of languages within minutes",
        "Closed contracts with studios & creators with cumulative 8-figure+ audience",
        "Built internal tools to supercharge lead generation, A/B testing, team communication, etc.",
      ],
      link: "https://www.camb.ai/",
      tech: [
        "K8s",
        "Docker",
        "Amazon S3",
        "NextJS",
        "Tailwind",
        "Python",
        "Phantombuster",
      ],
    },
    {
      name: "Harvard Student Agencies (HSA): DEV",
      role: "PM & SWE",
      description: [
        "Youngest-ever full-time employee of HSA (started working there @ 14 with all others being college students)",
        "Dev is a freelancing firm, so here are all the contracts I worked on:",
        <span>
          <a
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() =>
              openModal([
                Giphting1,
                Giphting2,
                Giphting3,
                Giphting4,
                Giphting5,
                Giphting6,
                Giphting7,
              ])
            }
          >
            Giphting:
          </a>{" "}
          Digital marketplace for last-minute gift-giving,
        </span>,
        <span>
          <a
            onClick={() =>
              openModal([Fork1, Fork2, Fork3, Fork4, Fork5, Fork6], "pc")
            }
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "underline" }}
          >
            Fork:
          </a>{" "}
          Spearheaded a team of 4 engineers to develop an AI-based career
          pathways generator to help individuals get from starting role to dream
          job
        </span>,
        <span>
          <a
            onClick={() =>
              openModal([
                Lorestry1,
                Lorestry2,
                Lorestry3,
                Lorestry4,
                Lorestry5,
                Lorestry6,
                Lorestry7,
                Lorestry8,
                Lorestry9,
              ])
            }
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "underline" }}
          >
            Lorestry:
          </a>{" "}
          Newborn and childcare development platform for caretakers
        </span>,
        <span>
          <a
            href="https://tutoring.hsa.net/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "underline" }}
          >
            HSA Tutoring:
          </a>{" "}
          Complete website redesign and development for the largest student-run
          tutoring agency in the world
        </span>,
        <span>
          <a
            onClick={() =>
              openModal([THS1, THS2, THS3, THS4, THS5, THS6], "pc")
            }
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "underline" }}
          >
            The Harvard Shop:
          </a>{" "}
          Created custom shopify layer in Liquid to build a decisions-day bundle
          sequence for the Harvard shop
        </span>,
      ],
      link: "https://www.hsa.net/",
      tech: [
        "React",
        "GraphQL",
        "ElephantSQL",
        "Elastic Beanstalk",
        "Amazon S3",
        "Express",
        "Google Cloud Console APIs",
        "Tensorflow",
        "Liquid",
        "Shopify API",
      ],
    },
    {
      name: "Dots (YC S21)",
      role: "Full-Stack",
      description: [
        "Engineered a multichannel payouts API that automates manual processes for marketplaces",
        "Built summary export accounting routes for holistic representation of wallet finances",
        "Revamped pricing page & nav components on website",
      ],
      link: "https://dots.dev/",
      tech: [
        "React",
        "Amazon ECS",
        "Flask (Python)",
        "PostgreSQL",
        "Amazon S3",
        "Tailwind",
      ],
    },
    {
      name: "Pair AI (YC S23)",
      role: "Full-Stack",
      description: [
        "Built MVP of a mobile-first education platform that makes learning as easy as watching a TikTok",
        "Created TagMango integrations with app that allowed course instructors to automatically connect with students",
        "Built scrapers for social media platforms to create 'identities' for creators on the platform",
        "Connected Firebase Webhooks and Netlify Serverless Functions to automate account creation from 3rd-party sources and streamline authentication",
      ],
      images: [Pair1, Pair2, Pair3, Pair4],
      link: "https://pairai.com",
      tech: [
        "React Native",
        "Firebase Webhooks",
        "Netlify Serverless Functions",
        "MongoDB",
        "Twilio",
        "Sentry",
        "Zapier",
      ],
    },
    {
      name: "NLP Researcher @ UPenn Linguistics",
      role: "Researcher",
      description: [
        "Conducted research w/ Ph.D. Soham Dan on Project Debater, a project aimed at bringing debating ability to computers",
      ],
      tech: ["B.E.R.T", "PyTorch", "Tensorflow"],
    },
  ];

  const moreCompanies = [
    {
      name: "Amelia [Company]",
      description: [
        "Mental health management for companies",
        "Created frontend user interface for launch on Flutter, Xcode, and other packages for video players, audio players, etc.",
        "Led in-app data visualization and local storage to personalize user app experience",
      ],
    },
    {
      name: "Locals Insight [Company]",
      description: [
        "Receive tourist recommendations from locals",
        "Developed authentication sequence of MVP in Ionic + Angular and used Firebase to set up authentication infrastructure with login and registration capabilities",
        "Connected the Google Maps API to the app and configured in-app route navigation for recommended locations",
      ],
    },
    {
      name: "SashiDo.io [Company]",
      description: [
        "Global serverless infrastructure for DevOps companies",
        "Completed Floralens project using machine learning to classify plant species based on a picture",
        "Connected Firebase ML vision and Teachable Machine to train image segmentation machine learning model",
        "Developed app infrastructure to integrate Tensorflow support and integrated SashiDo authentication services",
      ],
    },
    {
      name: "Opal [Company]",
      description: [
        "Worked with React Native, Redux, and AWS dynamo database to create chat feature and log messages in backend database",
        "Refined landing page animations and worked with figma to suggest product design changes",
      ],
    },
    {
      name: "Scintilla [Project]",
      description: [
        "Built virtual reality worlds to create a more immersive educational learning experience and corresponding app for GTM infrastructure",
      ],
    },
    {
      name: "SwiftService [Project]",
      description: ["AI customer service representative"],
      tech: ["Python", "Streamlit", "HuggingFace", "Firebase"],
    },
    {
      name: "Pioneer's Guild [Project]",
      description: [
        "Built up newsletter about indie hacking to 7,000+ subscribers",
      ],
      tech: ["React", "Google Sheets API", "Twilio", "BS4"],
    },
    {
      name: "Agapi [Project]",
      description: ["Tinder for job applications"],
      tech: ["React Native", "Amazon S3", "Firebase"],
    },
    {
      name: "Formaspeak [Project]",
      description: ["Dynamic AI Form Generator"],
      tech: ["React", "Tailwind CSS", "OpenAI API", "Firebase", "AWS DynamoDB"],
    },
    {
      name: "Kwit [Project]",
      description: ["Mobile app to alleviate smoking addictions"],
      tech: ["Flutter", "Geolocation API", "Firebase", "MongoDB"],
    },
    {
      name: "BraceForecast [Project]",
      description: ["Mobile app to do self-serve, ML-powered dental diagnoses"],
      tech: ["Flutter", "Google Maps API", "CV", "Tensorflow", "Heroku"],
    },
    {
      name: "Petcare [Project]",
      description: [
        "Mobile app to assess nutritional value of pet foods & optimize pet diet",
      ],
      tech: [
        "Firebase Vision Models",
        "Custom OCR Algorithm",
        "Flutter",
        "Firebase",
      ],
    },
    {
      name: "Revonew [Project]",
      description: ["Financial budgeting app"],
      tech: ["React Native", "Tensorflow", "Firebase", "DialogFlow"],
    },
    {
      name: "Kinect [Project]",
      description: ["Proximity-based social connection app"],
      tech: ["Flutter", "Geolocation API", "Firebase"],
    },
    {
      name: "Various scrapers [Project]",
      description: [
        "YouTube Bot: Email notifications when creator releases new videos, auto-reply to comments, get video transcripts and summaries",
        "Twitter Scraper: Scrape all tweets from a profile",
        "LinkedIn Scraper: Scrape all LinkedIn posts from a profile",
      ],
      tech: ["Flutter", "Geolocation API", "Firebase"],
    },
  ];

  const openModal = (images, medium) => {
    setSelectedProjectImages(images);
    setMedium(medium);
    setShowModal(true);
  };

  return (
    <div
      style={{
        width: isMobile ? screenWidth * 0.9 : screenWidth * 0.75,
        paddingRight: 40,
      }}
      className="flex flex-col"
    >
      {showModal && (
        <GalleryModal
          images={selectedProjectImages}
          medium={medium}
          onClose={() => setShowModal(false)}
        />
      )}
      <div
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: isMobile ? "baseline" : "center",
          marginTop: screenHeight * 0.03,
          justifyContent: "center",
        }}
      >
        <img
          alt="pfp"
          src={pfp}
          style={{
            height: screenHeight * 0.2,
            borderRadius: 15,
            marginRight: screenWidth * 0.02,
          }}
        />
        <div style={{ marginLeft: isMobile ? 0 : 5 }}>
          <h4
            className="urbanist"
            style={{
              fontWeight: 500,
              fontSize: 15,
              textAlign: "left",
              lineHeight: 1.75,
              marginTop: isMobile ? screenHeight * 0.02 : 0,
            }}
          >
            When first introduced to the world of Mad Libs in Python and Mario
            games in Scratch, I absolutely despised coding. Oblivious to its
            function, I'd copy code off Stack Overflow and later get exposed
            during "interviews" with my dad. It wasn't until a friend approached
            me with an app idea about banking for young people that I changed my
            perspective. Since then, coding has never been about building for
            the sake of building—as someone who has often struggled with
            self-confidence and expression, it's my way of sharing my theses
            with others on what needs to be solved and how. Here's some of the
            stuff I've worked on:
          </h4>
        </div>
      </div>
      {/* <div
        style={{
          marginTop: 20,
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <h5 style={{ width: "100%" }}>Tech Frequency:</h5>
        {techTags && Object.entries(techTags).map(([tech, { count, color }]) => (
          <div
            key={tech}
            style={{ display: "flex", alignItems: "center", margin: "5px" }}
          >
            <span
              style={{
                height: "10px",
                width: "10px",
                borderRadius: "50%",
                backgroundColor: color,
                marginRight: "5px",
              }}
            ></span>
            <span>{`${tech}: ${count} project(s)`}</span>
          </div>
        ))}
      </div> */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: isMobile ? "1fr" : "1fr 3fr 1fr",
          gap: isMobile ? "5px" : "10px",
          marginTop: screenHeight * 0.03,
          borderRadius: "15px",
          overflow: "hidden",
          backgroundColor: "transparent",
          marginBottom: screenHeight * 0.1,
        }}
      >
        {!isMobile && (
          <>
            <div
              style={{
                border: "1px solid",
                borderRadius: "15px",
                overflow: "hidden",
              }}
            >
              <div style={{ padding: "10px" }}>
                <strong>Name (Click to see ss) </strong>
              </div>
            </div>
            <div
              style={{
                border: "1px solid",
                borderRadius: "15px",
                overflow: "hidden",
              }}
            >
              <div style={{ padding: "10px" }}>
                <strong>Project Description</strong>
              </div>
            </div>
            <div
              style={{
                border: "1px solid",
                borderRadius: "15px",
                overflow: "hidden",
              }}
            >
              <div style={{ padding: "10px" }}>
                <strong>Tech</strong>
              </div>
            </div>
          </>
        )}
        {projects.map((project, index) => (
          <>
            <div
              style={{
                border: isMobile ? "none" : "1px solid",
                borderRadius: "15px",
                overflow: "hidden",
              }}
            >
              <button
                style={{
                  textAlign: "left",
                  width: "100%",
                  padding: "10px",
                  paddingLeft: isMobile ? "0px" : "10px",
                  textDecoration:
                    (project.images || project.link) &&
                    !(project.images && project.link)
                      ? "underline"
                      : "none",
                  border: "none",
                  background: "none",
                  fontSize: isMobile ? "17px" : "15px",
                }}
                onClick={() =>
                  project.images
                    ? openModal(project.images)
                    : window.open(project.link, "_blank")
                }
              >
                {project.name}
              </button>
              {project.images && project.link ? (
                <>
                  <button
                    style={{
                      textAlign: "left",
                      width: "100%",
                      paddingLeft: isMobile ? "0px" : "10px",
                      textDecoration: "underline",
                      border: "none",
                      background: "none",
                    }}
                    onClick={() =>
                      project.images
                        ? openModal(project.images)
                        : window.open(project.link, "_blank")
                    }
                  >
                    Screenshots
                  </button>
                  <button
                    style={{
                      textAlign: "left",
                      width: "100%",
                      paddingLeft: isMobile ? "0px" : "10px",
                      textDecoration: "underline",
                      border: "none",
                      background: "none",
                    }}
                    onClick={() => window.open(project.link, "_blank")}
                  >
                    Website
                  </button>
                </>
              ) : (
                <></>
              )}
            </div>
            <div
              style={{
                border: "1px solid",
                borderRadius: "15px",
                overflow: "hidden",
              }}
            >
              <div style={{ padding: "10px" }}>
                {project.description.map((desc, descIndex) => (
                  <p key={descIndex}>- {desc}</p>
                ))}
              </div>
            </div>
            <div
              style={{
                border: "1px solid",
                borderRadius: "15px",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  padding: "10px",
                  display: "flex",
                  gap: "5px",
                  flexWrap: "wrap",
                }}
              >
                {project.tech &&
                  project.tech.map((tech, techIndex) => (
                    <div
                      key={techIndex}
                      className="urbanist"
                      style={{
                        fontSize: "14px",
                        backgroundColor: techTags[tech]
                          ? techTags[tech].color
                          : "grey",
                        borderRadius: "10px",
                        padding: "5px 10px",
                        color: "white",
                      }}
                    >
                      {tech}
                    </div>
                  ))}
              </div>
            </div>
            {isMobile && index !== projects.length - 1 ? (
              <div>
                <hr
                  style={{
                    width: "100%",
                    border: "0.5px solid black",
                    marginTop: "15px",
                  }}
                />
              </div>
            ) : null}
          </>
        ))}
      </div>
      <div className="mt-[-3rem] mb-[2rem] text-center items-center justify-center urbanist text-semibold flex">
        <hr className="flex-grow border-[0.01rem] border-black" />
        <button onClick={() => setPortfolioExpanded(!portfolioExpanded)}>
          <div className="flex flex-col">
            <span className="mx-4">
              {portfolioExpanded ? "See less..." : "See more..."}
            </span>
            <div className="border-1 border-black"></div>
          </div>
        </button>
        <hr className="flex-grow border-[0.01rem] border-black" />
      </div>
      {portfolioExpanded && (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: isMobile ? "1fr" : "1fr 3fr",
            gap: isMobile ? "5px" : "10px",
            marginTop: -screenHeight * 0.01,
            borderRadius: "15px",
            overflow: "hidden",
            backgroundColor: "transparent",
            marginBottom: screenHeight * 0.1,
          }}
        >
          {moreCompanies.map((project, index) => (
            <>
              <div
                style={{
                  border: isMobile ? "none" : "1px solid",
                  borderRadius: "15px",
                  overflow: "hidden",
                }}
              >
                <p
                  style={{
                    textAlign: "left",
                    width: "100%",
                    padding: "10px",
                    paddingLeft: isMobile ? "0px" : "10px",
                    border: "none",
                    background: "none",
                    fontSize: isMobile ? "17px" : "15px",
                  }}
                >
                  {project.name}
                </p>
              </div>
              <div
                style={{
                  border: "1px solid",
                  borderRadius: "15px",
                  overflow: "hidden",
                  gridColumn: "2 / 3",
                }}
              >
                <div style={{ padding: "10px" }}>
                  {project.description.map((desc, descIndex) => (
                    <p key={descIndex}>- {desc}</p>
                  ))}
                  {index >= 5 && project.tech && <p> - Tech: {project.tech.join(", ")}</p>}
                </div>
              </div>
              {isMobile && index !== moreCompanies.length - 1 ? (
                <div>
                  <hr
                    style={{
                      width: "100%",
                      border: "0.5px solid black",
                      marginTop: "15px",
                    }}
                  />
                </div>
              ) : null}
            </>
          ))}
        </div>
      )}
    </div>
  );
};
