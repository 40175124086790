import React, { useEffect, useState } from "react";
import pfp from "../assets/pfp.jpg";

export const Content = () => {
  const screenHeight = window.innerHeight;
  const screenWidth = window.innerWidth;
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 900);
    };

    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);

    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  });
  return (
    <div
      style={{ width: isMobile ? screenWidth * 0.9 : screenWidth * 0.75, paddingRight: 40 }}
      className="flex flex-col"
    >
      <div
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: isMobile ? "baseline" : "center",
          marginTop: screenHeight * 0.03,
          // justifyContent: 'center',
        }}
      >
        <img
          alt="pfp"
          src={pfp}
          style={{
            height: screenHeight * 0.15,
            borderRadius: 15,
            marginRight: isMobile ? 0 : screenWidth * 0.02,
          }}
        />
        <div style={{ marginLeft: isMobile ? 0 : 5 }}>
          <h1
            className="urbanist"
            style={{
              fontWeight: 600,
              fontSize: 15,
              textAlign: "left",
              lineHeight: 1.75,
              marginTop: isMobile ? screenHeight * 0.02 : 0,
            }}
          >
            Hey, I'm Sashv 👋
          </h1>
          <h4
            className="urbanist"
            style={{
              fontWeight: 500,
              fontSize: 15,
              textAlign: "left",
              lineHeight: 1.75,
            }}
          >
            I'm an undergrad @ Harvard studying CS, AM, & potentially
            Philosophy. I'm originally from Fremont, CA. Let me know if you're
            ever around and we'll have a good time : ). Here are a few quick
            things about me:
          </h4>
        </div>
      </div>
      <h4
        className="urbanist"
        style={{
          fontWeight: 500,
          fontSize: 15,
          textAlign: "left",
          marginTop: screenHeight * 0.02,
          lineHeight: 1.75,
        }}
      >
        - I'm a copoclephilist, meaning I love collecting keychains. I have an
        exquisite collection, maybe I'll add a scrapbook of my keychains on
        here soon.
      </h4>
      <h4
        className="urbanist"
        style={{
          fontWeight: 500,
          fontSize: 15,
          textAlign: "left",
          lineHeight: 1.75,
        }}
      >
        - I'm obsessed with game-pigeon 8-ball (and irl) and archery. Send a
        game to{" "}
        <a href="tel:+15106905448">
          <u>+15106905448</u>
        </a>{" "}
        anytime. I'll play, no questions asked.
      </h4>
      <h1
        className="urbanist"
        style={{
          fontWeight: 500,
          fontSize: 15,
          textAlign: "left",
          marginTop: screenHeight * 0.02,
          lineHeight: 1.75,
        }}
      >
        <b>Personal quirks aside, </b>I spent most of my time in high school on
        startup stuff. I've never really touched research (though I do kinda
        want to sometime) or competitions that make me an absolute academic
        weapon, but here's some stuff I personally found pretty cool &
        fulfilling:
      </h1>
      <div style={{ height: 8 }} />
      <h4
        className="urbanist"
        style={{
          fontWeight: 500,
          fontSize: 15,
          textAlign: "left",
          lineHeight: 1.75,
        }}
      >
        - Built an EdTech startup that taught people to speak with confidence.
        {/* <a href="https://calendly.com/sashvd/" target="_blank">
              <u>I'm happy to tell you all about them.</u>
            </a> */}
      </h4>
      <div style={{ height: 8 }} />
      <h4
        className="urbanist"
        style={{
          fontWeight: 500,
          fontSize: 15,
          lineHeight: 1.75,
          textAlign: "left",
        }}
      >
        - Worked on a content localization startup that helped make content more
        accessible to the world. Built a dubbing engine that could
        convert video content into 100+ languages and used it to help a buncha
        creators and studios share their content worldwide.
      </h4>
      <div style={{ height: 8 }} />
      <h4
        className="urbanist"
        style={{
          fontWeight: 500,
          fontSize: 15,
          textAlign: "left",
          marginBottom: screenHeight * 0.1,
          lineHeight: 1.75,
        }}
      >
        - Worked as a SWE and such at a couple of startups (ranging from pre-MVP
        college startups to post-Series A startups). Working in early-stage
        FinTech & the Creator Industry were the most fun.
      </h4>
    </div>
  );
};

